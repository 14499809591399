import './Footer.css'

export default function Footer() {
    return (
        <footer>
            <div className="container">
                <img src="./img/logo_white.png" alt="etruria retail" />
                <p>© 2021 Etruria Retail - <a href="https://www.etruriaretail.it/privacy-cookie-policy/" target="_blank" rel="noopener noreferrer">Privacy & Cookie policy</a></p>
            </div>
        </footer>
    )
}