import React from 'react';
import {
  Switch,
  Route,
  BrowserRouter as Router, 
} from 'react-router-dom';
import Header from './components/ui/header/Header';
import RootPage from './components/pages/RootPage';
import ThankYou from './components/pages/thank-you/ThankYou';
import Footer from './components/ui/footer/Footer';

function App() {
  return (
    <Router>
      <Header/>
      <Switch>
        <Route exact path="/" component={RootPage} />
        <Route exact path="/thankyou" component={ThankYou} />
      </Switch>
      <Footer />
    </Router>);
}

export default App;
