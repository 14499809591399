import React from "react";
import './Header.css'

export default function Header() {
    return  ( 
        <header>
            <div className="container">
                <img src="./img/logo.png" alt="etruria retail" />
            </div>
        </header>
  );
}