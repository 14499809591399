
export default class Validators {

  public static validateFidelityCardNumber = (value: string): boolean => {
    return value.length === 13 && value.charAt(0) === '0' && value.charAt(1) === '4'
  }

  public static validateEmail = (value: string): boolean => {
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    return value ? regex.test(value) : true;
  }

  public static validatePhoneNumber = (value: string): boolean => {
    const regex = /^[0-9\s]*$/;
    return value ? regex.test(value) && value.length <= 11 && value.length >= 9 : true
  }

}


