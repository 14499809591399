import React from "react";
import './ThankYou.css'

export default function ThankYou() {

    return (
        <main id="thank-you">
            <div className="container">
                <h1>Grazie!</h1>
                <p>I tuoi dati sono stati registrati con successo</p>
            </div>
        </main>
    )
}