import { UseFormRegister } from "react-hook-form";
import { Values } from "../../../models/dtos/CardDTO";
import EditForm, { EditFormHookObject } from "../../../models/EditForm";
import Validators from "../../../tools/Validators";

interface SectionContattiProps {
    card: Values,
    formHookObj: EditFormHookObject
}

export default function SectionContatti(props: SectionContattiProps){
    const register: UseFormRegister<EditForm> = props.formHookObj.register;
    const { errors } = props.formHookObj.formState;
    
    return (
        <div className="section">
        <div className="row">
          <div className="col-12">
            <h4>Contatti</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <label >Tel. Fisso</label>
            <input type="text" id="telefono" defaultValue={props.card.anagrafica.telefono}
                {...register("telefono", { required: false, validate: value => Validators.validatePhoneNumber(value) })} />
            {errors.telefono && <span className="error">Inserisci un numero di telefono valido</span>}
          </div>
          <div className="col-6">
            <label >Cellulare</label>
            <input type="text" id="cellulare" defaultValue={props.card.anagrafica.cellulare}
                {...register("cellulare", { required: false, validate: value => Validators.validatePhoneNumber(value) })} />
            {errors.cellulare && <span className="error">Inserisci un numero di telefono valido</span>}
          </div>
          <div className="col-12">
            <label >Email<sup>*</sup></label>
            <input type="text" id="email" defaultValue={props.card.anagrafica.EMail}
                  {...register("email", { required: true, validate: value => Validators.validateEmail(value) })} />
              {errors.email && <span className="error">Inserisci un indirizzo email valido</span>}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <span className="small-txt">I campi segnati con un asterisco (<span id="asterisk">*</span>) sono obbligatori</span>
          </div>
        </div>
      </div>
    )
}