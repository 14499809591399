import React, { useState, useEffect } from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import Select from "react-select";
import { Values } from "../../../models/dtos/CardDTO";
import EditForm, { EditFormHookObject } from "../../../models/EditForm";
import provinces from "../../../tools/Provinces";
import moment from "moment";
import RestAPI from "../../../tools/RestAPI";
import "./SectionAnagrafici.css";

interface SectionAnagraficiProps {
    card: Values,
    formHookObj: EditFormHookObject
}

interface OptionSelect {
  label: string
  value: string
}

export default function SectionAnagrafici(props: SectionAnagraficiProps){
    const register: UseFormRegister<EditForm> = props.formHookObj.register;
    const setValue: UseFormSetValue<EditForm> = props.formHookObj.setValue;
    const { errors } = props.formHookObj.formState;

    const [cities, setCities] = useState<OptionSelect[]>([]);
    const [provincia, setProvincia] = useState<string>(props.card.anagrafica.provincia);
    const [cittaSelect, setCittaSelect] = 
      useState<OptionSelect>({label:props.card.anagrafica.citta, value: props.card.anagrafica.codiceCitta});
    const [codiceCitta, setCodiceCitta] = useState<string>(props.card.anagrafica.codiceCitta);
    const [citta, setCitta] = useState<string>(props.card.anagrafica.citta);
    
    const dataDiNascita = props.card.anagrafica.dataDiNascita;
    const dataDiNascitaFormatted = moment(dataDiNascita.split('T')[0], 'YYYY-MM-DD').format('DD/MM/YYYY')

    const handleProvinciaSelectOnChange = (selectValue: OptionSelect | null) => {
      setProvincia(selectValue!.value);   
      if(selectValue) fetchData(selectValue.value);
      setCittaSelect({label: '', value: ''});
      setCitta('');
      setCodiceCitta('');

      setValue('provincia', selectValue!.value, { shouldValidate: true })
    }

    const handleCittaSelectOnChange = (selectValue: OptionSelect | null) => {
      setCittaSelect(selectValue!);
      setCitta(selectValue!.label);
      setCodiceCitta(selectValue!.value);

      setValue('citta', selectValue!.label, { shouldValidate: true })
      setValue('codiceCitta', selectValue!.value, { shouldValidate: true })
    }

    const fetchData = async(prov: string) => {
      setCities( (await RestAPI.getCities(prov)).map((city) => {
              return { label: city.comune, value: city.code}
          })
      );
    }

    useEffect(() => {
      fetchData(provincia);
    }, [provincia])

    return (
        <div className="section">
        <div className="row">
          <div className="col-12">
            <h4>Dati anagrafici</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <label>Nome<sup>*</sup></label>
            <label className='disabled'>{props.card.anagrafica.nome}</label>
          </div>
          <div className="col-6">
            <label >Cognome<sup>*</sup></label>
            <label className='disabled'>{props.card.anagrafica.cognome}</label>
          </div>
          <div className="col-6">
            <label >Data di Nascita<sup>*</sup></label>
            <label className='disabled'>{dataDiNascitaFormatted}</label>
          </div>
          <div className="col-6">
            <label >Comune di nascita<sup>*</sup></label>
            <label className='disabled'>{props.card.anagrafica.comuneDiNascita}</label>
          </div>
          <div className="col-6">
            <label >Sesso<sup>*</sup></label>
            <label className='disabled'>{props.card.anagrafica.sesso}</label>
          </div>
          <div className="col-6">
            <label >Codice fiscale<sup>*</sup></label>
            <label className='disabled'>{props.card.anagrafica.codiceFiscale}</label>
          </div>
          <div className="col-6">
            <label >Provincia</label>
            <div className="select-wrapper">
              <Select
                value={provinces.find(c => c.value === provincia)}
                className="basic-single"
                classNamePrefix="select"
                id="provinciaSelect"
                options={provinces}
                onChange={val => handleProvinciaSelectOnChange(val)}
              />
            </div>
            <input type="hidden" id="provincia" value={provincia} {...register("provincia", { required: false })} />
            {errors.provincia && <span className="error">Obbligatorio</span>}
          </div>   
          <div className="col-6">
            <label>Comune</label>
            <div className="select-wrapper">
              <Select
                  className="basic-single"
                  classNamePrefix="select"
                  id="cittaSelect"
                  options={cities}
                  value={cittaSelect}
                  onChange={val => handleCittaSelectOnChange(val)}
                />
            </div>
            <input type="hidden" id="codiceCitta" value={codiceCitta} {...register("codiceCitta", { required: false })} />
            <input type="hidden" id="citta" value={citta} {...register("citta", { required: false })} />
            {errors.codiceCitta && <span className="error">Obbligatorio</span>}
          </div>
          <div className="col-6">
            <label >Via/Piazza</label>
            <input type="text" id="indirizzo"  defaultValue={props.card.anagrafica.indirizzo}
              {...register("indirizzo", { required: false })} />
            {errors.indirizzo && <span className="error">Obbligatorio</span>}
          </div>
          <div className="col-3">
            <label >Numero Civico</label>
            <input type="text" id="numeroCivico"  defaultValue={props.card.anagrafica.numeroCivico}
              {...register("numeroCivico", { required: false })} />
            {errors.numeroCivico && <span className="error">Obbligatorio</span>}
          </div>
          <div className="col-3">
            <label >CAP</label>
            <input type="text" id="cap"  defaultValue={props.card.anagrafica.cap}
              {...register("cap", { required: false, pattern: /[0-9]{5}/i })} />
            {errors.cap && <span className="error">Inserire un CAP valido</span>}
          </div>
          
        </div>
      </div>
    )
}