import axios from "axios";
import { CardDTO } from "../models/dtos/CardDTO";
import CityDTO from "../models/dtos/CityDTO";
import EditForm from "../models/EditForm";
import SearchForm from "../models/SearchForm";

export default class RestAPI {

    private static getUrl(endpoint: string): string{
        const baseUrl = RestAPI.getApiBaseUrl();
        if (!baseUrl) throw new Error('No API_URL specified');
        return `${baseUrl}${endpoint}`;
    }

    private static getApiBaseUrl(): string{
        if(!process.env.REACT_APP_API_BASE_URL) throw new Error('[E] - API_BASE_URL not configured')
        return process.env.REACT_APP_API_BASE_URL;
    }

    public static getFidelityCardData = async (formData: SearchForm): Promise<CardDTO> => {
        const queryParams = '?codiceTessera=' + formData.fidelityCard + '&dataDiNascita=' + formData.dateOfBirth;
        const endpoint = this.getUrl('/v1/card') + queryParams;
        const response = await axios.get<CardDTO>(endpoint);
        return response.data;
    }

    public static updateFidelityCardData = async (formData: EditForm): Promise<CardDTO> => {
        const endpoint = this.getUrl('/v1/card');
        const response = await axios.put<CardDTO>(endpoint, formData);
        return response.data;
    }

    public static getCities = async (prov: string): Promise<CityDTO[]> => {
        const queryParams = '?provincia=' + prov;
        const endpoint = this.getUrl('/v1/cities') + queryParams;
        const response = await axios.get<CityDTO[]>(endpoint);
        return response.data;
    }
}