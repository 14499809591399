import { UseFormRegister } from "react-hook-form";
import EditForm, { EditFormHookObject } from "../../../models/EditForm";
import CheckBoxForm from "../checkbox/CheckBoxForm";

export default function RowPrivacyTerms(props: {formHookObj: EditFormHookObject }){
    const { errors } = props.formHookObj.formState;
    const register: UseFormRegister<EditForm> = props.formHookObj.register;

    return (
        <div className="row">
          <div className="col-12">
            <div className="checkbox-wrapper">
              <label>
                <CheckBoxForm 
                    name='conditionalTerms' 
                    value={false}
                    register={register}
                />
                <p>Ho letto e accettato l'<a href='https://yqdata001.blob.core.windows.net/yq-media/informativa.pdf' target='_blank' rel="noreferrer">informativa della privacy</a> completa.</p>
              </label>
            </div>
          </div>
          {errors.conditionalTerms && <span className="error">Obbligatorio</span>}
        </div>
    )
}