import { UseFormRegister } from "react-hook-form";
import { Privacy } from "../../../models/dtos/CardDTO";
import EditForm, { EditFormHookObject } from "../../../models/EditForm";
import CheckBoxForm from "../checkbox/CheckBoxForm";

export default function RowPrivacyMK(props: { privacy: Privacy[], formHookObj: EditFormHookObject }){
    const { errors } = props.formHookObj.formState;
    const register: UseFormRegister<EditForm> = props.formHookObj.register;

    return (
        <div className="row">
          <div className="col-12">
            <div className="policy-item">
              <div className="checkbox-wrapper">
                <label>
                  <CheckBoxForm 
                    name='privacyMk' 
                    value={props.privacy.filter((val) => val.codice === 'MK').map((val) => val.consenso)[0]}
                    register={register}  
                  />
                  <p><strong>a)</strong> Al trattamento dei dati personali per le finalità di marketing del Titolare (al fine di ricevere materiale informativo, promozionale e/o partecipare a ricerche di mercato mediante tutti i mezzi di comunicazione disponibili)</p>
                </label>
              </div>
            </div>
          </div>
          {errors.privacyMk && <span className="error">Obbligatorio</span>}
        </div>
    )
}