
const provinces = [
    { value: 'AG', label: 'Agrigento' },
    { value: 'AL', label: 'Alessandria' },
    { value: 'AN', label: 'Ancona' },
    { value: 'AO', label: 'Aosta' },
    { value: 'AP', label: 'Ascoli Piceno' },
    { value: 'AQ', label: "L'Aquila" },
    { value: 'AR', label: 'Arezzo' },
    { value: 'AT', label: 'Asti' },
    { value: 'AV', label: 'Avellino' },
    { value: 'BA', label: 'Bari' },
    { value: 'BG', label: 'Bergamo' },
    { value: 'BI', label: 'Biella' },
    { value: 'BL', label: 'Belluno' },
    { value: 'BN', label: 'Benevento' },
    { value: 'BO', label: 'Bologna' },
    { value: 'BR', label: 'Brindisi' },
    { value: 'BS', label: 'Brescia' },
    { value: 'BT', label: 'Barletta-Andria-Trani' },
    { value: 'BZ', label: 'Bolzano' },
    { value: 'CA', label: 'Cagliari' },
    { value: 'CB', label: 'Campobasso' },
    { value: 'CE', label: 'Caserta' },
    { value: 'CH', label: 'Chieti' },
    { value: 'CL', label: 'Caltanissetta' },
    { value: 'CN', label: 'Cuneo' },
    { value: 'CO', label: 'Como' },
    { value: 'CR', label: 'Cremona' },
    { value: 'CS', label: 'Cosenza' },
    { value: 'CT', label: 'Catania' },
    { value: 'CZ', label: 'Catanzaro' },
    { value: 'EN', label: 'Enna' },
    { value: 'FC', label: 'Forlì-Cesena' },
    { value: 'FE', label: 'Ferrara' },
    { value: 'FG', label: 'Foggia' },
    { value: 'FI', label: 'Firenze' },
    { value: 'FM', label: 'Fermo' },
    { value: 'FR', label: 'Frosinone' },
    { value: 'GE', label: 'Genova' },
    { value: 'GO', label: 'Gorizia' },
    { value: 'GR', label: 'Grosseto' },
    { value: 'IM', label: 'Imperia' },
    { value: 'IS', label: 'Isernia' },
    { value: 'KR', label: 'Crotone' },
    { value: 'LC', label: 'Lecco' },
    { value: 'LE', label: 'Lecce' },
    { value: 'LI', label: 'Livorno' },
    { value: 'LO', label: 'Lodi' },
    { value: 'LT', label: 'Latina' },
    { value: 'LU', label: 'Lucca' },
    { value: 'MB', label: 'Monza e Brianza' },
    { value: 'MC', label: 'Macerata' },
    { value: 'ME', label: 'Messina' },
    { value: 'MI', label: 'Milano' },
    { value: 'MN', label: 'Mantova' },
    { value: 'MO', label: 'Modena' },
    { value: 'MS', label: 'Massa e Carrara' },
    { value: 'MT', label: 'Matera' },
    { value: 'NA', label: 'Napoli' },
    { value: 'NO', label: 'Novara' },
    { value: 'NU', label: 'Nuoro' },
    { value: 'OR', label: 'Oristano' },
    { value: 'PA', label: 'Palermo' },
    { value: 'PC', label: 'Piacenza' },
    { value: 'PD', label: 'Padova' },
    { value: 'PE', label: 'Pescara' },
    { value: 'PG', label: 'Perugia' },
    { value: 'PI', label: 'Pisa' },
    { value: 'PN', label: 'Pordenone' },
    { value: 'PO', label: 'Prato' },
    { value: 'PR', label: 'Parma' },
    { value: 'PT', label: 'Pistoia' },
    { value: 'PU', label: 'Pesaro e Urbino' },
    { value: 'PV', label: 'Pavia' },
    { value: 'PZ', label: 'Potenza' },
    { value: 'RA', label: 'Ravenna' },
    { value: 'RC', label: 'Reggio Calabria' },
    { value: 'RE', label: 'Reggio Emilia' },
    { value: 'RG', label: 'Ragusa' },
    { value: 'RI', label: 'Rieti' },
    { value: 'RM', label: 'Roma' },
    { value: 'RN', label: 'Rimini' },
    { value: 'RO', label: 'Rovigo' },
    { value: 'SA', label: 'Salerno' },
    { value: 'SI', label: 'Siena' },
    { value: 'SO', label: 'Sondrio' },
    { value: 'SP', label: 'La spezia' },
    { value: 'SR', label: 'Siracusa' },
    { value: 'SS', label: 'Sassari' },
    { value: 'SU', label: 'Sud Sardegna' },
    { value: 'SV', label: 'Savona' },
    { value: 'TA', label: 'Taranto' },
    { value: 'TE', label: 'Teramo' },
    { value: 'TN', label: 'Trento' },
    { value: 'TO', label: 'Torino' },
    { value: 'TP', label: 'Trapani' },
    { value: 'TR', label: 'Terni' },
    { value: 'TS', label: 'Trieste' },
    { value: 'TV', label: 'Treviso' },
    { value: 'UD', label: 'Udine' },
    { value: 'VA', label: 'Varese' },
    { value: 'VB', label: 'Verbano Cusio Ossola' },
    { value: 'VC', label: 'Vercelli' },
    { value: 'VE', label: 'Venezia' },
    { value: 'VI', label: 'Vicenza' },
    { value: 'VR', label: 'Verona' },
    { value: 'VT', label: 'Viterbo' },
    { value: 'VV', label: 'Vibo Valentia' },
  ]


export default provinces;