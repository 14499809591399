import { useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import EditForm, { KeyEditForm } from '../../../models/EditForm';

interface CheckBoxProps {
    name:  KeyEditForm,
    value: boolean,
    register: UseFormRegister<EditForm>
}


export default function CheckBoxForm (props: CheckBoxProps) {
  const [checked, setChecked] = useState(props.value);
  const [value, setValue] = useState(props.value.toString());
  const register: UseFormRegister<EditForm> = props.register;

  const handleOnChange = () => {
    setChecked(!checked)
    setValue(checked ? 'false' : 'true' )
  }

  const validateCheckbox = ():boolean => {
    return checked;
  }

  return (
      <input 
        type="checkbox" 
        id={props.name} 
        checked={checked}
        value={value}
        {...register(props.name, {
          required: false,
          validate: validateCheckbox })} 
        onChange={handleOnChange} />
  )
}