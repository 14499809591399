import React, { useState } from "react";
import { CardDTO } from "../../models/dtos/CardDTO";
import Hero from "../ui/hero/Hero";
import EditCard from "./edit-card/EditCard";
import SearchCard from "./search-card/SearchCard";

export default function RootPage(props: any) {
    const [card, setCard] = useState<CardDTO | undefined>(undefined)

    const handleChange = (newValue: CardDTO | undefined) => {
      setCard(newValue);
    }

    if(card && card.value){
      return (
        <><Hero/>             
        <main>
          <EditCard card={card.value} />
        </main></>
      )
    }
    return (
      <><Hero/> 
      <main>
      <SearchCard onChange={handleChange} />
      </main></>
    )
    
}

