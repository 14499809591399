import { UseFormRegister } from "react-hook-form";
import { Values } from "../../../models/dtos/CardDTO";
import EditForm, { EditFormHookObject } from "../../../models/EditForm";

interface SectionCardProps {
  card: Values,
  formHookObj: EditFormHookObject
}

export default function SectionCard(props: SectionCardProps){
    const register: UseFormRegister<EditForm> = props.formHookObj.register;

    return (
      <div className="section">
        <div className="row">
          <div className="col-12">
            <h4>Carta fedeltà</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <label>Numero<sup>*</sup></label>
            <label className='disabled'>{props.card.codiceTessera}</label>
          </div>
          <input type="hidden" id="codiceTessera" value={props.card.codiceTessera} {...register("codiceTessera", { required: true })} />
          <input type="hidden" id="dataDiNascita" value={props.card.anagrafica.dataDiNascita} {...register("dataDiNascita", { required: true })} />
          <input type="hidden" id="pdv" value={props.card.pdv} {...register("pdv", { required: true })} />
        </div>
      </div>
    )
}