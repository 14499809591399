import { UseFormRegister } from "react-hook-form";
import { Privacy } from "../../../models/dtos/CardDTO";
import EditForm, { EditFormHookObject } from "../../../models/EditForm";
import CheckBoxForm from "../checkbox/CheckBoxForm";

export default function RowPrivacyCM(props: { privacy: Privacy[], formHookObj: EditFormHookObject  }){
    const { errors } = props.formHookObj.formState;
    const register: UseFormRegister<EditForm> = props.formHookObj.register;

    return (
        <div className="row">
          <div className="col-12">
            <div className="policy-item">
              <div className="checkbox-wrapper">
                <label>
                    <CheckBoxForm 
                        name='privacyCm' 
                        value={props.privacy.filter((val) => val.codice === 'MK').map((val) => val.consenso)[0]}
                        register={register}  
                      />
                  <p><strong>c)</strong> Alla comunicazione dei propri dati personali agli affiliati collegati con Etruria Soc. Coop. per finalità di profilazione delle abitudini e scelte di consumo (al fine di ricevere scelte personalizzate)</p>
                </label>
              </div>
            </div>
          </div>
          {errors.privacyCm && <span className="error">Obbligatorio</span>}
        </div>
    )
}