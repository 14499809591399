import { useState } from "react";
import { useForm } from "react-hook-form";
import { CardDTO, Error, Values } from "../../../models/dtos/CardDTO";
import EditForm from "../../../models/EditForm";
import RestAPI from "../../../tools/RestAPI";
import SectionAnagrafici from "../../ui/section-anagrafici/SectionAnagrafici";
import SectionCard from "../../ui/section-card/SectionCard";
import SectionContatti from "../../ui/section-contatti/SectionContatti";
import SectionPrivacy from "../../ui/section-privacy/SectionPrivacy";
import './EditCard.css'
import { useHistory } from "react-router-dom";


export default function EditCard(props: { card: Values }) {
    const history = useHistory();
    const { register, handleSubmit, setValue, formState, control } = useForm<EditForm>();
    const [errorApi, setErrorApi] = useState<Error[] | undefined>(undefined);

    const onSubmit = async (formData: EditForm) => {
      // console.log('<EditCard> sono in onSubmit()')
      // console.log(JSON.stringify(formData));
      
      try {
        const response: CardDTO = await RestAPI.updateFidelityCardData(formData);
        // console.log(JSON.stringify(card))
        if(response.code !== 200 || response.message === 'KO' || response.errorList !== null){
            setErrorApi(response.errorList);
        }else{
            // console.log('<EditCard> sono in onSubmit() tentando di cambiare pagina')
            history.push("/thankyou");
        }
      } catch (err: any) {
        if(err.response){
          setErrorApi(err.response.data.errorList)
        }
      }
      
    }

    return  ( 
      <div className="container">
        <form id="form" onSubmit={ handleSubmit(onSubmit) }>
          <SectionCard card={props.card} formHookObj={{ register, setValue, formState, control }} />
          <SectionAnagrafici card={props.card} formHookObj={{ register, setValue, formState, control }} />
          <SectionContatti card={props.card} formHookObj={{ register, setValue, formState, control }} />
          <SectionPrivacy card={props.card} formHookObj={{ register, setValue, formState, control }} />
          <div className="section">
            <div className="row">
                <div className="col-12">
                    {errorApi && <span className="error">{errorApi[0].message}</span>}
                </div>
            </div>
            <div className="row">
              <div className="col-12">
                <button type="submit" className="button-save">Salva</button>
              </div>
            </div>
          </div>
        </form>
      </div> 
    )
}