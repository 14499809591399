import { useState } from "react";
import { useForm } from "react-hook-form";
import RestAPI from '../../../tools/RestAPI'
import './SearchCard.css'
import { CardDTO, Error } from "../../../models/dtos/CardDTO";
import SearchForm from "../../../models/SearchForm";
import Validators from "../../../tools/Validators";


export default function SearchCard(props: { onChange : any}) {
    const { register, handleSubmit, formState: { errors } } = useForm<SearchForm>();
    const [errorApi, setErrorApi] = useState<Error[] | undefined>(undefined);

    const onSubmit = async (formData: SearchForm) => {
        const card: CardDTO = await RestAPI.getFidelityCardData(formData)
        // console.log(JSON.stringify(card))
        if(card.code !== 200 || card.message === 'KO' || card.errorList !== null){
            setErrorApi(card.errorList);
        }else{
            // console.log('<SearchCard> sono in onSubmit() tentando di cambiare pagina')
            props.onChange(card)
            // window.location.href = '/data'
        }
    }

    // console.log(watch("fidelityCard")) // watch input value by passing the name of it

    return  (
        <>
        <form id="form" onSubmit={ handleSubmit(onSubmit) }>
        <div className="section">
            <div className="row">
                <div className="col-6">
                    <label>Numero carta fedeltà<sup>*</sup></label>
                    <input type="text" id="fidelity-card"
                        {...register("fidelityCard", { required: true, validate: value => Validators.validateFidelityCardNumber(value) })} />
                    {errors.fidelityCard && <span className="error">Inserisci un numero carta fedeltà valido</span>}

                </div>
                <div className="col-6">
                    <label>Data di Nascita<sup>*</sup></label>
                    <input type="date" id="date-of-birth"
                        {...register("dateOfBirth", { required: true, pattern: /\d{4}-\d{2}-\d{2}/i })} />
                    {errors.dateOfBirth && <span className="error">Obbligatorio</span>}
                </div>
            </div>
        </div>
        <div className="section">
            <div className="row">
                <div className="col-12">
                    {errorApi && <span className="error">{errorApi[0].message}</span>}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <button type="submit" className="button-search">Cerca</button>
                </div>
            </div>
        </div>
        </form></>
  );
}
