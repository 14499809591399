import { Values } from "../../../models/dtos/CardDTO";
import { EditFormHookObject } from "../../../models/EditForm";
import RowPrivacyCM from "./RowPrivacyCM";
import RowPrivacyMK from "./RowPrivacyMK";
import RowPrivacyPR from "./RowPrivacyPR";
import RowPrivacyTerms from "./RowPrivacyTerms";
import './SectionPrivacy.css'

interface SectionPrivacyProps {
    card: Values,
    formHookObj: EditFormHookObject
}

export default function SectionPrivacy(props: SectionPrivacyProps){

    return (
        <>
        <div className="section">
        <div className="row">
          <div className="col-12">
            <span className="tiny-txt">APPROVAZIONE DEL REGOLAMENTO DEL SERVIZIO CARTA DI FIDELIZZAZIONE E MANIFESTAZIONE DEL CONSENSO AL TRATTAMENTO DEI DATI PERSONALI</span>
            <span className="tiny-txt">Il sottoscritto, presa visione Regolamento del Servizio carta di fidelizzazione e dell’informativa sul trattamento dei dati personali ne approva i contenuti e inoltre presta il proprio consenso</span>
          </div>
        </div>
        <RowPrivacyMK privacy={props.card.privacy} formHookObj={props.formHookObj}/>
        <RowPrivacyPR privacy={props.card.privacy} formHookObj={props.formHookObj}/>
        <RowPrivacyCM privacy={props.card.privacy} formHookObj={props.formHookObj}/>
      </div>
      <div className="section">
        <RowPrivacyTerms formHookObj={props.formHookObj}/>
      </div>
      </>
    )
}