import React from "react";
import './Hero.css'

export default function Hero() {
    return  ( 
        <section id="hero" style={{ backgroundImage: "url(/img/background.jpg)" }}>
            <div className="container">
                <div className="title">
                    <h1>Sempre con te Card Etruria Retail</h1>
                    <p>Inserisci tutti i dati richiesti per completare le informazioni legate alla carta fedeltà</p>
                </div>
            </div>
        </section>
  );
}