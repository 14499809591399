import { UseFormRegister } from "react-hook-form";
import { Privacy } from "../../../models/dtos/CardDTO";
import EditForm, { EditFormHookObject } from "../../../models/EditForm";
import CheckBoxForm from "../checkbox/CheckBoxForm";

export default function RowPrivacyPR(props: { privacy: Privacy[], formHookObj: EditFormHookObject }){
    const { errors } = props.formHookObj.formState;
    const register: UseFormRegister<EditForm> = props.formHookObj.register;

    return (
        <div className="row">
          <div className="col-12">
            <div className="policy-item">
              <div className="checkbox-wrapper">
                <label>
                    <CheckBoxForm 
                        name='privacyPr' 
                        value={props.privacy.filter((val) => val.codice === 'PR').map((val) => val.consenso)[0]}
                        register={register}  
                      />
                  <p><strong>b)</strong> Al trattamento dei dati personali per finalità di profilazione delle abitudini e scelte di consumo da parte del Titolare (al fine di ricevere scelte personalizzate)</p>
                </label>
              </div>
            </div>
          </div>
          {errors.privacyPr && <span className="error">Obbligatorio</span>}
        </div>
    )
}